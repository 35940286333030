/*
  import { actionItemStore } from '@/services/BoardActionItems/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await actionItemStore.dispatch ('getActionItemList', {
      hoaId
    });
  */

  async getActionItemList({}, { hoaID, sourceBoardPackageID }) {
    console.log('in getActionItemList...')
    try {
      const results = await kms.get('/BoardMeetings/ActionItem/List', {
        params: {
          hoaID,
          sourceBoardPackageID
        }
      })

      if (isDebug == true) console.debug('getActionItemList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Board Action Items list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getActionItemStatusList({}) {
    console.log('in getActionItemStatusList...')
    try {
      const results = await kms.get('/BoardMeetings/ActionItemStatus/List')

      if (isDebug == true) console.debug('getActionItemStatusList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Board Action Item Status list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getActionItemPriorityList({}) {
    console.log('in getActionItemPriorityList...')
    try {
      const results = await kms.get('/BoardMeetings/ActionItemPriority/List')

      if (isDebug == true) console.debug('getActionItemPriorityList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Board Action Item Priority list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getBoardPackagesList({}, { hoaID }) {
    console.log('in getBoardPackagesList...')
    try {
      const results = await kms.get('/BoardMeetings/BoardPackage/List', {
        params: {
          hoaID
        }
      })

      if (isDebug == true) console.debug('getBoardPackagesList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Board Packages list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getActionItemById({}, { actionItemID }) {
    try {
      console.debug('in getActionItemById...')
      const actionItem = await kms.get(`/BoardMeetings/ActionItem/${actionItemID}`)

      if (isDebug == true) console.debug('getActionItemById=' + JSON.stringify(actionItem))

      return {
        result: actionItem
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the action item.`)
      console.error(exception)
    }

    return {
      actionItem: {}
    }
  },

  // Update Action Item
  async updateActionItem({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/BoardMeetings/ActionItem`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.actionItemID > 0) {
        notifyMessage(`Successfully updated the board action item.`)
        done()
      } else {
        notifyError('There was a problem updating this board action item.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this board action item.`)
      console.error(exception)
    }
  },

  async addActionItem({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addActionItem')
    try {
      const results = await kms.post(`/BoardMeetings/ActionItem`, {
        actionItemID: payload.actionItemID,
        sourceBoardPackageID: payload.sourceBoardPackageID,
        actionItemStatusID: payload.actionItemStatusID,
        actionItemPriorityID: payload.actionItemPriorityID,
        title: payload.title,
        description: payload.description,
        assignedToAspNetUserID: payload.assignedToAspNetUserID,
        completedBy: payload.completedBy,
        completedDate: null,
        completeByDate: payload.completeByDate,
        hoaID: payload.hoaID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.actionItemID > 0) {
        notifyMessage(`Successfully added the action item.`)
        done()
      } else {
        notifyError('There was a problem adding this action item.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this action item.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const actionItemStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
