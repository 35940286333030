<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div itemdetails class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6" v-if="actionItemStatusList">
                    <valid-select
                      label="Status"
                      placeholder="Status"
                      vid="actionItemStatus"
                      v-model="formData.actionItemStatusID"
                      aria-required="The status is required"
                      rules="required"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="option in actionItemStatusList"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div class="column is-6">
                    <label class="label">Complete By Date</label>
                    <DatePicker
                      v-model="formData.completeByDate"
                      ariaLabel="Completion Date"
                      ref="completeByDateComponent"
                    />
                  </div>
                </div>
                <div class="columns" v-if="actionItemStatusIdDone === formData.actionItemStatusID">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Completed By"
                      label="Completed By"
                      type="text"
                      vid="CompletedBy"
                      placeholder="Completed By"
                      spellcheck="true"
                      aria-label="Completed by"
                      class="is-small"
                      v-model="formData.completedBy"
                      :disabled="viewOnly"
                    />
                  </div>
                  <div class="column is-6">
                    <label class="label">Completed Date</label>
                    <DatePicker
                      :readonly="viewOnly"
                      v-model="formData.completedDate"
                      ariaLabel="Completion Date"
                      ref="completedDateComponent"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="AssignedTo"
                      label="Assigned To"
                      type="text"
                      vid="Assigned To"
                      placeholder="Assigned To"
                      spellcheck="true"
                      aria-label="Assigned To"
                      class="is-small"
                      v-model="formData.assignedTo"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6" v-if="sourceBoardPackagesList">
                    <valid-select
                      label="Source Board Package"
                      placeholder="Source Board Package"
                      vid="sourceBoardPackageID"
                      v-model="formData.sourceBoardPackageID"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="option in sourceBoardPackagesList"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6" v-if="actionItemPriorityList">
                    <valid-select
                      label="Priority"
                      placeholder="Priority"
                      vid="actionItemPriority"
                      v-model="formData.actionItemPriorityID"
                      aria-required="The priority is required"
                      rules="required"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="option in actionItemPriorityList"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div class="column is-5">
                    &nbsp;
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="Title"
                      label="Title"
                      type="text"
                      vid="Title"
                      placeholder="Title"
                      spellcheck="true"
                      aria-label="Title"
                      class="is-small"
                      v-model="formData.title"
                      :disabled="viewOnly"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="Description"
                      label="Description"
                      type="textarea"
                      vid="Description"
                      placeholder="Description"
                      spellcheck="true"
                      aria-label="Description"
                      class="is-small"
                      v-model="formData.description"
                      :disabled="viewOnly"
                      rules="required"
                    />
                  </div>
                </div>
                <div>
                  <button type="submit" :disabled="viewOnly" class="button is-primary is-rounded">
                    Update
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import DatePicker from '@/components/inputs/DatePicker'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'ActionItemDetails',
  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    DatePicker
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  created() {},

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        actionItemDetail: { title: 'Action Item Detail' }
      }
    }
  }
}
</script>
