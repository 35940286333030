import { actionItemStore } from '@/services/BoardActionItems/store'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'
import { notifyError } from '@/services/notify'
import moment from 'moment'

var DEFAULT_FORM_VALUES = {
  actionItemStatusID: 0,
  actionItemPriorityID: 0,
  completedDate: null,
  completeByDate: null,
  completedBy: '',
  assignedTo: '',
  sourceBoardPackage: '',
  title: '',
  description: ''
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true

    const dateFormat = 'MM/DD/YYYY hh:mm a'

    console.debug('route values=' + JSON.stringify(this.$route.params))

    this.actionItemID = this.$route.params.id

    this.actionItemTitle = this.$route.params.title
    this.viewOnly = this.$route.params.viewonly === 'true' ? true : false

    console.debug('viewOnly=' + this.viewOnly)

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.loadActionItemStatus()
    await this.loadActionItemPriority()
    await this.loadSourceBoardPackages()

    await this.loadActionItemByID()
      .then(() => {
        if (this.actionItem) {
          try {
            this.formData.actionItemStatusID = _get(this.actionItem, 'actionItemStatusID', 0)
            this.formData.actionItemPriorityID = _get(this.actionItem, 'actionItemPriorityID', 0)

            this.formData.completedBy = _get(this.actionItem, 'completedBy', '')

            this.formData.assignedTo = _get(
              this.actionItem,
              'assignedToIdentityInformation.name',
              ''
            )

            this.formData.sourceBoardPackageID = _get(this.actionItem, 'sourceBoardPackageID', 0)

            this.formData.title = _get(this.actionItem, 'title', '')

            this.formData.description = _get(this.actionItem, 'description', '')

            const completeByDateTemp = _get(this.actionItem, 'completeByDate', null)
            if (completeByDateTemp) {
              const newDate = new Date(completeByDateTemp)
              console.debug('newDate=' + newDate)
              this.$refs.completeByDateComponent.selected = newDate
            }

            this.formData.completeByDate = parseDate(
              _get(this.actionItem, 'completeByDate', null),
              dateFormat
            )
          } catch (e) {
            console.debug('error while loading the mail delivery item data:' + e)
          }
        }
      })
      .then(() => {
        this.loadCompletedDateComponent()
      })

    this.loading = false
  },

  loadCompletedDateComponent() {
    const completedDateTemp = _get(this.actionItem, 'completedDate', null)
    if (completedDateTemp) {
      try {
        const newDate = new Date(moment(completedDateTemp).format('MM/DD/YYYY'))
        console.debug('completedDateTemp=' + newDate)
        if (this.$refs.completedDateComponent) {
          this.$refs.completedDateComponent.selected = newDate
        }
      } catch (e) {
        //this.$refs.completedDateComponent.selected = new Date(today)
      }
    }
  },

  chooseBoardPackage() {},

  async loadActionItemByID() {
    await actionItemStore
      .dispatch('getActionItemById', {
        actionItemID: this.actionItemID
      })
      .then(({ result }) => {
        if (result) {
          this.actionItem = result
        }
      })
  },

  async loadSourceBoardPackages() {
    const dateFormat = 'MM/DD/YYYY hh:mm a'

    await actionItemStore
      .dispatch('getBoardPackagesList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.sourceBoardPackagesList = list.map(m => ({
            label: `${m.type} Session ${
              m.meetingDate ? '-' + parseDate(m.meetingDate, dateFormat) : ''
            }`,
            value: m.boardPackageID
          }))
        }
      })
  },

  async loadActionItemStatus() {
    await actionItemStore.dispatch('getActionItemStatusList').then(({ list }) => {
      if (list) {
        this.actionItemStatusList = list.map(m => ({
          label: m.name,
          value: m.actionItemStatusID
        }))

        const actionItemStatusIdDoneTemp = list.filter(f => f.name === 'Done' || f.name === 'done')

        if (
          actionItemStatusIdDoneTemp !== undefined &&
          actionItemStatusIdDoneTemp &&
          actionItemStatusIdDoneTemp[0]
        ) {
          this.actionItemStatusIdDone = actionItemStatusIdDoneTemp[0].actionItemStatusID
        }
      }
    })
  },

  async loadActionItemPriority() {
    await actionItemStore.dispatch('getActionItemPriorityList').then(({ list }) => {
      if (list) {
        this.actionItemPriorityList = list.map(m => ({
          label: m.name,
          value: m.actionItemPriorityID
        }))
      }
    })
  },

  getPayload() {
    const payload = {
      actionItemID: this.actionItem.actionItemID,
      sourceBoardPackageID: this.formData.sourceBoardPackageID,
      actionItemStatusID: this.formData.actionItemStatusID,
      actionItemPriorityID: this.formData.actionItemPriorityID,
      title: this.formData.title,
      description: this.formData.description,
      assignedToAspNetUserID: this.actionItem.assignedToAspNetUserID,
      completedBy:
        this.actionItemStatusIdDone &&
        this.actionItemStatusIdDone === this.formData.actionItemStatusID
          ? this.formData.completedBy
          : null,
      completedDate:
        this.actionItemStatusIdDone &&
        this.actionItemStatusIdDone === this.formData.actionItemStatusID
          ? this.formData.completedDate
          : null,
      completeByDate: this.formData.completeByDate,
      hoaID: this.actionItem.hoaID
    }

    return payload
  },

  async onFormSubmit() {
    if (!this.formData) {
      notifyError('There was a problem saving your action item.')
      return
    }

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (
      this.actionItemStatusIdDone === this.formData.actionItemStatusID &&
      (this.formData.completedBy === '' || !this.formData.completedBy)
    ) {
      notifyError('Please enter a value for Completed By.')
      return
    }

    await actionItemStore.dispatch('updateActionItem', {
      payload: this.getPayload(),
      done: async () => {
        await this.reload()
      }
    })

    loadingComponent.close()
  }
}
